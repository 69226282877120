<!-- =========================================================================================
    File Name: ItemListView.vue
    Description: Item Component - List VIew
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div
    class="lg:p-4 bg-white shadow-md rounded-lg flex flex-col lg:flex-row"
    v-on="$listeners"
  >
    <!-- image -->
    <slot name="remove" />
    <div class="w-full lg:w-1/5 cursor-pointer">
      <!-- img :src="item.images[0].image" :alt="item.name" class="p-4" -->
      <!-- src="https://domf5oio6qrcr.cloudfront.net/medialibrary/7798/c5a743ce-27ab-4b0e-b3cf-167a7d29e45316207267658642.jpg" -->
      <div class="hidden lg:block">
        <img
          @click="navigate_to_detail_view"
          style="border-radius: 1.5rem !important"
          height="100px"
          width="100px"
          class="w-full object-cover"
          :src="item.images[0].image"
        />
      </div>
      <div class="flex items-center justify-center lg:hidden p-4">
        <img
          @click="navigate_to_detail_view"
          style="border-radius: 1.5rem !important"
            height="100px"
          width="100px"
          class="object-cover"
          :src="item.images[0].image"
        />
      </div>
    </div>

    <!-- product -->
    <div
      class="p-4 lg:p-0 flex flex-col justify-center lg:pl-5 w-full lg:w-2/5"
    >
      <div class="text-lg opacity-75 hidden">{{ item.productType }}</div>
      <div
        style="font-size: 16px;"
        class="font-semibold cursor-pointer title-min-height"
        @click="navigate_to_detail_view"
      >
        {{ item.name }}
      </div>
      <div
        v-if="item.retoolItemDiscount"
        style="font-size: 14px;"
        class="mt-2 font-semibold text-primary my-3"
      >
        <span  class=" line-through">${{ item.price | formatDecimals }}</span> ${{ item.price-item.retoolItemDiscount | formatDecimals }}
      </div>
      <div
        v-else
        style="font-size: 14px;"
        class="mt-2 font-semibold text-primary my-3"
      >
        ${{ item.price | formatDecimals }}
      </div>

      <bundle-details :bundle="item.bundleItems" v-if="item.bundleItems" />
    </div>

    <!-- action -->
    <div
      class="flex flex-row justify-start lg:justify-end items-center w-full lg:w-2/5"
    >
      <div class="w-full mr-10 lg:w-auto flex flex-row lg:flex-col justify-end items-end lg:justify-none ">
          <span> Subtotal
          <span v-if="item.retoolTotalDiscount" style="font-size: 14px;" class=" font-semibold">${{ (item.price*item.quantity) - item.retoolTotalDiscount | formatDecimals }}</span>
          <span v-else style="font-size: 14px;" class=" font-semibold">${{ item.price*item.quantity | formatDecimals }}</span></span>
      </div>
      <slot name="action-buttons" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      quantity: 0,
    };
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isInCart() {
      return (itemId) => this.$store.getters["eCommerce/isInCart"](itemId);
    },
    isInWishList() {
      return (itemId) => this.$store.getters["eCommerce/isInWishList"](itemId);
    },
  },
  methods: {
    toggleItemInWishList(item) {
      this.$store.dispatch("eCommerce/toggleItemInWishList", item);
    },
    selectedChangeQuantity(quantity) {
      this.item.selectedQuantity = quantity;
    },
    additemInCart(item) {
      this.$store.dispatch("eCommerce/additemInCart", item);
    },
    cartButtonClicked(item) {
      this.isInCart(item.objectID)
        ? this.$router.push("/apps/eCommerce/checkout").catch(() => {})
        : this.additemInCart(item);
    },
    navigate_to_detail_view() {
      if (this.$store.state.AppActiveUser.userType === "doctorClinic") {
        this.$router
          .push({
            name: "DoctorClinicProductDetail",
            params: { id: this.item.productStoreId },
          })
          .catch(() => {});
      } else {
        this.$router
          .push({
            name: "ClinicProductDetail",
            params: { id: this.item.productStoreId },
          })
          .catch(() => {});
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.list-view-item {
  .item-name,
  .item-description {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .item-name {
    -webkit-line-clamp: 2;
  }

  .item-description {
    -webkit-line-clamp: 5;
  }

  .grid-view-img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    transition: 0.35s;
  }

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
</style>
